import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import Cookies from 'js-cookie';
import { defineMessages, L10NContext, T, t } from '@sm/intl';
import {
  RespondentSurveyCapabilityProvider,
  ExternalQuestion,
  RespondentSurveyCapabilityProviderProps,
} from '@sm/question-widgets/respondent-survey';
import { getClientEnvironmentDetails } from '@sm/utils';
import { useSurveyTheme } from '@sm/webassets/SurveyTheme/context';
import classNames from 'classnames';
import { FiveHundredErrorPage } from '@sm/webassets/FiveHundredError';
import {
  SubmitSurveyResponseForMultipageMutation,
  SubmitSurveyResponseForMultipageMutationVariables,
  SubmitSurveyResponseMutation,
  SubmitSurveyResponseMutationVariables,
} from '~lib/generatedGqlTypes';
import submitSurveyResponse from '../SubmitResponsesV2.graphql';
import submitSurveyResponseV3 from '../SubmitResponsesV3.graphql';
import { useVerifySurveyVersion } from '../hooks/useVerifySurveyVersion';
import { BypassBlock } from '~app/components/core/BypassBlock';
import { SurveyTitle } from '~app/components/Survey/SurveyTitleV2';
import createHeadingLevel from '../helper/createHeadingLevel';
import SurveyFooter from '~app/components/Survey/FooterContainer';
import { PageTitle } from '~app/components/Survey/PageTitle';
import { ANALYTICS_EVENTS } from '~app/components/amplitudeEnums';
import { PageDescription } from '~app/components/Survey/PageDescription';
import { SurveyButton } from '~app/components/Survey/SurveyButton';
import { Layout } from '../components/Layout';
import { QuestionList } from '../components/QuestionList';
import { PersistentProgressBar } from '~app/components/Survey/PersistentProgressBar';
import ModifiedSurveyDialog from '~app/components/Survey/ModifiedSurveyDialog';
import { decryptAnswers, overrideAnswersFromLocalStorage } from '../helper/localStoreAnswers';
import extrapolateSurveyData from '../helper/extrapolateSurveyData';
import useStyles from '../useStyles';
import { Logo } from '../components/Logo';
import { PageNavigation } from '../components/PageNavigation';
import { ClassicProgressBar } from '~app/components/Survey/ClassicProgressBar';
import { ActiveViewScrollManager, SurveyFormatView } from '~app/components/Survey/SurveyFormat';
import SurveyFormat from '../helper/surveyFormatEnum';
import WeblinkConfirmationCheckbox from '~app/components/Survey/WeblinkConfirmationCheckbox';
import { runAfterFocusChangedFn, useGlobalAfterFocusChangedHandler } from '../validation/delayedValidation';
import { useLayoutEffect, useAppSelectorV2 as useAppSelector, useAppDispatchV2 as useAppDispatch } from '~app/hooks';
import { decodeHtmlEntities } from '../helper/decodeHtmlEntities';
import {
  selectHasVersionChanged,
  setErrors,
  focusFirstQuestionError,
  validatePage,
  validateConfirmationEmail,
} from './slices/errorsSlice';
import { isThankYouView, isSurveyTakingView } from '../helper/currentSurveyView';
// needed to add SMQuestions copy to the translations list
import '../smQuestionsCopy';

import {
  selectSurvey,
  selectActivePage,
  selectCollector,
  selectQuestions,
  selectAnswers,
  updateAnswer,
  submitSurvey,
  submitResponsesV3,
  selectClientToken,
  selectSurveyView,
  surveyComplete,
  selectPagePath,
  selectCurrentPageId,
  selectCollectorKey,
  selectEncrytedSurveyParams,
  selectSurveyOwnerPackageId,
  setActivePage,
  setCookie,
  postSurveyCompleteCleanup,
  selectQuestionsWithAnswersCount,
} from './slices/surveySlice';

import { LanguageSelect } from '../components/LanguageSelect';
import sendAmplitudeEvent from '~helpers/sendAmplitudeEvent';

const COPY = defineMessages({
  SKIP_LINK_CONTENT: {
    id: 'SurveyTaking.Survey.SkipLinkContent',
    defaultMessage: 'Skip to content',
    description: '[Type: label][Vis: med] - Skip to content link label (Accessibility)',
  },
  OK_LABEL: {
    id: 'SurveyTaking.Survey.OK_LABEL',
    defaultMessage: 'OK',
    description: '[Type: button][Vis: high] - question/description ok label for One-Question-At-A-Time mode',
  },
});

type SurveyProps = {
  pageRequestId: string;
  showRequestId: boolean;
  /** Local or Language code (e.g. `en` or `fr-CA`) */
  reqLocale?: string;
  /** https://reactjs.org/docs/dom-elements.html#suppresshydrationwarning */
  suppressHydrationWarning?: true;
  showWeblinkConfirmationToggle?: boolean;
};

const Survey: React.FC<SurveyProps> = ({
  pageRequestId,
  showRequestId,
  reqLocale = 'en',
  showWeblinkConfirmationToggle = false,
}) => {
  const survey = useAppSelector(selectSurvey);
  const collector = useAppSelector(selectCollector);
  const currentPageId = useAppSelector(selectCurrentPageId) ?? '';
  const encrytedSurveyParams = useAppSelector(selectEncrytedSurveyParams);
  const pagePath = useAppSelector(selectPagePath);
  const activePage = useAppSelector(selectActivePage);
  const activePageMeta = activePage?.surveyPage;
  const clientToken = useAppSelector(selectClientToken);
  let questions = useAppSelector(selectQuestions);
  const answers = useAppSelector(selectAnswers);
  const questionsWithAnswersCount = useAppSelector(selectQuestionsWithAnswersCount);
  const surveyOwnerPackageId = useAppSelector(selectSurveyOwnerPackageId);
  const collectorKey = useAppSelector(selectCollectorKey) ?? '';
  const localStorageAnswers = decryptAnswers(collectorKey);
  const hasVersionChanged = useAppSelector(selectHasVersionChanged);
  const pagePathIndex: number = pagePath.indexOf(parseInt(currentPageId, 10)) || -1;
  const currentPagePosition = pagePathIndex + 1 || 1;
  const currentSurveyView = useAppSelector(selectSurveyView);
  const isCurrentViewCustomThankYou = isThankYouView(currentSurveyView);
  const isCurrentViewSurveyTaking = isSurveyTakingView(currentSurveyView);
  const dispatch = useAppDispatch();
  const surveyTheme = useSurveyTheme();
  const [hasError, setHasError] = useState(false);
  const { isRTL } = useContext(L10NContext);
  const handlePageReload = useCallback(() => {
    // scroll to top so the viewport starts at top of page on reload
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto', // prevent smooth scroll
    });
    window.location.reload();
  }, []);

  const layoutContainerRef = React.useRef<HTMLDivElement>(null);
  const persistentProgressRef = React.useRef<HTMLDivElement>(null);
  const partialContainerRef = React.useRef<HTMLDivElement>(null);
  const logoContainerRef = React.useRef<HTMLDivElement>(null);
  const toolbarContainerRef = React.useRef<HTMLDivElement>(null);
  const topContainerRef = React.useRef<HTMLDivElement>(null);
  const mainContainerRef = React.useRef<HTMLDivElement>(null);
  const pageNavigationRef = React.useRef<HTMLDivElement>(null);
  const surveyFooterRef = React.useRef<HTMLDivElement>(null);
  const mainRef = React.useRef<HTMLDivElement>(null);
  /** Queries the survey version and applies version-changed errors when called */
  const verifySurveyVersion = useVerifySurveyVersion();

  /**
   * calculateOffsetBottom
   * Calculate bottom margin for the SurveyFooter. This ensures there is
   * enough room for footer links and last question can move to centre page.
   */
  const [offsetBottom, setOffsetBottom] = useState(0);
  useLayoutEffect(() => {
    const calculateOffsetBottom = (): void => {
      if (survey?.format !== SurveyFormat.OQAAT) return;
      const footerHeight = surveyFooterRef.current?.offsetHeight ?? 0;
      const progressHeight = persistentProgressRef.current?.offsetHeight ?? 0;
      const offset = footerHeight + progressHeight;
      setOffsetBottom(survey?.design?.footer?.enabled ? window.innerHeight / 2 - offset : offset);
    };
    window.addEventListener('resize', calculateOffsetBottom);
    calculateOffsetBottom(); // run immediately
    return () => window.removeEventListener('resize', calculateOffsetBottom);
  }, [survey?.design?.footer?.enabled, survey?.format, surveyFooterRef, persistentProgressRef, setOffsetBottom]);
  useGlobalAfterFocusChangedHandler();

  // Weblink Confirmation Email Checkbox
  const [weblinkConfirmationEmail, setWeblinkConfirmationEmail] = useState('');
  const [weblinkConfirmationEmailChecked, setWeblinkConfirmationEmailChecked] = useState(false);
  const confirmationEmail = showWeblinkConfirmationToggle ? weblinkConfirmationEmail : null;

  // Whether the Done button should be disabled
  const [disableSubmit, setDisableSubmit] = useState(false);

  const dateStarted = React.useRef('');
  const smParamForSurveyComplete = React.useRef('');

  // Set StartTime on Load
  useEffect(() => {
    dateStarted.current = new Date().toISOString();
    if (pagePath?.length > 1) {
      // set obfuscated entity ids
      Cookies.set(`RE_${collectorKey}`, encrytedSurveyParams ?? '', { path: '/' });
      // set current page id
      Cookies.set(`REPID_${collectorKey}`, currentPageId, { path: '/' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * V3 Actions - Start
   */

  const [submitSurveyResponsesV3] = useMutation<
    SubmitSurveyResponseForMultipageMutation,
    SubmitSurveyResponseForMultipageMutationVariables
  >(submitSurveyResponseV3, {
    // uncomment this block after responses api is auth enabled
    context: {
      ...(clientToken && {
        headers: {
          Authorization: `Bearer ${clientToken}`,
        },
      }),
    },
  });

  const _submitSurveyV3 = useCallback(async () => {
    runAfterFocusChangedFn();
    const dateCompleted = new Date().toISOString();
    // disable the Done button while submitting
    setDisableSubmit(true);
    await verifySurveyVersion();

    if (showWeblinkConfirmationToggle) {
      await dispatch(
        validateConfirmationEmail({ isChecked: weblinkConfirmationEmailChecked, emailValue: weblinkConfirmationEmail })
      );
    }

    const isValid = await dispatch(validatePage(false)).unwrap();

    if (isCurrentViewSurveyTaking) {
      if (!isValid) {
        dispatch(focusFirstQuestionError());
        setDisableSubmit(false);
        return;
      }

      // to be completed / cleaned up respondnetID
      const respondentId = '';
      const nextPageId = '';

      const response = await dispatch(
        submitResponsesV3({
          mutation: submitSurveyResponsesV3,
          dateStarted: dateStarted.current,
          dateCompleted,
          reqLocale,
          currentPageId,
          respondentId,
          nextPageId,
          isPrevious: false,
          isValid,
          isFinalSubmit: true,
          collectorKey,
          confirmationEmail,
        })
      ).unwrap();

      smParamForSurveyComplete.current = response?.encryptedSMParamforQuizResults ?? '';

      if (!response?.success) {
        dispatch(setErrors(response?.userErrors || []));
        // re-enable Done button if survey submission fails
        setDisableSubmit(false);
      } else {
        if (!collector?.allowMultipleResponses) {
          dispatch(
            setCookie({ name: `RP_${collector?.id}`, value: response?.encryptedCookieValue ?? '', expiration: 90 })
          );
        }
        // reset current page id
        Cookies.set(`REPID_${collectorKey}`, response?.surveyPage?.id ?? '', { path: '/' });
        sendAmplitudeEvent(ANALYTICS_EVENTS.SURVEY_COMPLETE, {
          survey,
          collector,
          surveyOwnerPackageId,
          respondent: response?.respondent,
          questions,
          confirmationEmailCheckboxIsChecked: !!weblinkConfirmationEmail.length,
        });
        dispatch(
          surveyComplete({
            smParamForSurveyComplete: (smParamForSurveyComplete.current || response?.encryptedSmParam || '') as string,
            reqLocale,
          })
        );
        dispatch(postSurveyCompleteCleanup({ collectorKey }));
      }
    } else {
      dispatch(surveyComplete({ smParamForSurveyComplete: smParamForSurveyComplete.current, reqLocale }));
    }
  }, [
    dispatch,
    isCurrentViewSurveyTaking,
    submitSurveyResponsesV3,
    currentPageId,
    verifySurveyVersion,
    reqLocale,
    collectorKey,
    collector,
    survey,
    surveyOwnerPackageId,
    questions,
    showWeblinkConfirmationToggle,
    weblinkConfirmationEmail,
    weblinkConfirmationEmailChecked,
    confirmationEmail,
  ]);

  const [submitPageResponsesV3] = useMutation<
    SubmitSurveyResponseForMultipageMutation,
    SubmitSurveyResponseForMultipageMutationVariables
  >(submitSurveyResponseV3, {
    context: {
      ...(clientToken && {
        headers: {
          Authorization: `Bearer ${clientToken}`,
        },
      }),
    },
    onCompleted: mutationResult => {
      const surveyPage = mutationResult.submitSurveyResponse?.surveyPage;
      questions = surveyPage?.surveyPageQuestions;
      const pageResponses = overrideAnswersFromLocalStorage(questions, localStorageAnswers);
      dispatch(
        setActivePage({
          activePage: {
            surveyPage: {
              ...surveyPage,
              id: surveyPage?.id ?? '',
              position: surveyPage?.position ?? 0,
              firstQuestionNumber: surveyPage?.firstQuestionNumber ?? 0,
              heading: surveyPage?.heading ?? '',
              subHeading: surveyPage?.subHeading ?? '',
              surveyPageQuestions: {
                items: surveyPage?.surveyPageQuestions?.items ?? [],
              },
            },
          },
          questions,
          answers: pageResponses,
          clientToken: mutationResult.submitSurveyResponse?.respondent?.clientToken ?? 'Invalid client token.',
        })
      );
      // set current page id
      Cookies.set(`REPID_${collectorKey}`, surveyPage?.id ?? '', { path: '/' });
      // set new respondent id, respondent type
      Cookies.set(`RE_${collectorKey}`, mutationResult.submitSurveyResponse?.encryptedSmParam ?? '', { path: '/' });
    },
  });

  const _submitPageV3 = useCallback(
    async (pageId: string, nextPageId: string, isPrevious = false) => {
      runAfterFocusChangedFn();
      const dateCompleted = new Date().toISOString();
      // disable the Navigation buttons
      setDisableSubmit(true);
      await verifySurveyVersion();
      const isValid = await dispatch(validatePage(isPrevious))?.unwrap();

      if (isCurrentViewSurveyTaking) {
        if (!isValid && !isPrevious) {
          // only focus and block progression for next, not prev
          dispatch(focusFirstQuestionError());
          setDisableSubmit(false);
          return;
        }

        // ASC to be completed / cleaned up respondnetID
        const respondentId = '';

        const response = await dispatch(
          submitResponsesV3({
            mutation: submitPageResponsesV3,
            dateStarted: dateStarted.current,
            dateCompleted,
            reqLocale,
            currentPageId: pageId,
            nextPageId,
            respondentId,
            isPrevious,
            isValid,
            isFinalSubmit: false,
            collectorKey,
            confirmationEmail,
          })
        )?.unwrap();
        // re-enable Done button if survey submission fails
        setDisableSubmit(false);
        // Shift focus to the top of the page and scroll there
        document.getElementById(`main-content-${survey?.id}`)?.focus();
        window.scrollTo(0, 0);

        if (!response?.success) {
          dispatch(setErrors(response?.userErrors || []));
        } else {
          const isFirstPage = pagePath[0] === Number(pageId);
          if (!isPrevious && isFirstPage) {
            dispatch(
              setCookie({ name: `RP_${collector?.id}`, value: response?.encryptedCookieValue ?? '', expiration: 90 })
            );
            sendAmplitudeEvent(ANALYTICS_EVENTS.SURVEY_PARTIAL, { survey, collector, surveyOwnerPackageId });
          }
        }
      }
    },
    [
      dispatch,
      isCurrentViewSurveyTaking,
      submitPageResponsesV3,
      reqLocale,
      collectorKey,
      verifySurveyVersion,
      survey,
      collector,
      surveyOwnerPackageId,
      pagePath,
      confirmationEmail,
    ]
  );

  /*
   * V3 Actions - End
   */

  /*
   * V2 Actions - Start
   */

  const [submitCurrentResponses] = useMutation<SubmitSurveyResponseMutation, SubmitSurveyResponseMutationVariables>(
    submitSurveyResponse,
    {
      context: {
        ...(clientToken && {
          headers: {
            Authorization: `Bearer ${clientToken}`,
          },
        }),
      },
    }
  );

  const _submitSurvey = useCallback(async () => {
    runAfterFocusChangedFn();
    // dateCompleted has to be declared before validatePageResult due to an issue
    // with jest timers and promises which was breaking an integration test.
    const dateCompleted = new Date().toISOString();
    // disable the Done button while submitting
    setDisableSubmit(true);
    await verifySurveyVersion();

    if (showWeblinkConfirmationToggle) {
      await dispatch(
        validateConfirmationEmail({ isChecked: weblinkConfirmationEmailChecked, emailValue: weblinkConfirmationEmail })
      );
    }
    const isValid = await dispatch(validatePage(false)).unwrap();

    if (isCurrentViewSurveyTaking) {
      if (!isValid) {
        dispatch(focusFirstQuestionError());
        setDisableSubmit(false);
        return;
      }

      // disable the Done button while submitting

      const response = await dispatch(
        submitSurvey({
          // @todo: this type needs to be messaged
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          mutation: submitCurrentResponses,
          dateStarted: dateStarted.current,
          dateCompleted,
          reqLocale,
          isValid,
          confirmationEmail,
        })
      ).unwrap();

      smParamForSurveyComplete.current = response?.encryptedSMParamforQuizResults ?? '';

      if (!response?.success) {
        if (response?.userErrors) {
          dispatch(setErrors(response.userErrors));
          // re-enable Done button if survey submission fails
          setDisableSubmit(false);
        } else {
          setHasError(true);
        }
      } else {
        if (!collector?.allowMultipleResponses) {
          dispatch(
            setCookie({ name: `RP_${collector?.id}`, value: response?.encryptedCookieValue ?? '', expiration: 90 })
          );
        }

        dispatch(
          surveyComplete({
            smParamForSurveyComplete:
              smParamForSurveyComplete.current !== '' ? smParamForSurveyComplete.current : response?.encryptedSmParam,
            reqLocale,
          })
        );
        dispatch(postSurveyCompleteCleanup({ collectorKey }));
        sendAmplitudeEvent(ANALYTICS_EVENTS.SURVEY_COMPLETE, {
          survey,
          collector,
          respondent: response?.respondent,
          surveyOwnerPackageId,
          confirmationEmailCheckboxIsChecked: !!weblinkConfirmationEmail.length,
        });
      }
    } else {
      // the case for custom thank you views
      dispatch(surveyComplete({ smParamForSurveyComplete: smParamForSurveyComplete.current, reqLocale }));
    }
    // If there is network latency causing delayed response
    // keep the button disabled to prevent user from clicking again
    // and submitting a duplicate response.
  }, [
    dispatch,
    isCurrentViewSurveyTaking,
    submitCurrentResponses,
    reqLocale,
    verifySurveyVersion,
    collector,
    survey,
    surveyOwnerPackageId,
    collectorKey,
    confirmationEmail,
    weblinkConfirmationEmail,
    weblinkConfirmationEmailChecked,
    showWeblinkConfirmationToggle,
  ]);

  /*
   * V2 Actions - End
   */

  const {
    surveyTitleContainer,
    mainContainer,
    layoutMainContainer,
    questionContainer,
    pageFooterContainer,
    sronlyFocus,
    pageDescriptionOkButton,
    pageNavigationContainer,
    surveyFooterContainer,
    classicProgressBarTop,
    customThankyouContainer,
  } = useStyles({ offsetBottom });

  if (!survey || Object.entries(survey).length === 0) {
    return null;
  }

  const {
    isSurveyTitleEnabled,
    showPageTitles,
    showPageNumbers,
    isFooterEnabled,
    isOQAAT,
    isClassicMode,
    isProgressBarEnabled,
    answerableQuestionCount,
    isQuestionNumberingEnabled,
    showRequiredQuestionAsterisks,
    surveyTitleAlignment,
    labels,
    totalPages,
    logo: { enabled: isLogoEnabled, ...logoProps },
    progressBar: { position: progressBarPosition, showPercent, showPageCount },
    languages,
  } = extrapolateSurveyData(survey);

  const surveyTitleHTML = survey?.titleHTML;
  const surveyId = survey?.id;

  /**
   * Page Header
   * calculate proper Page Title and Description based on current page
   */

  const { subHeading: pageDescription = '', heading: pageTitle, position: currentPage = 1 } = activePageMeta ?? {};
  const displayPageTitle = showPageTitles && !!pageTitle;
  /**
   * Skip to content will attempt to move the focus position (without actually focusing)
   * to the Survey Title (if exists), then Page title (if exists), finally to the
   * main-content container (<main>).
   * `tabIndex={-1}` is added to each element (backwards compat with <= IE 11)
   */
  const surveyTitleId = `survey-title-${surveyId}`;
  const pageTitleId = `page-title-${surveyId}`;
  const mainContentId = `main-content-${surveyId}`;
  const skipContentId = mainContentId;

  const nextHeadingElement = createHeadingLevel();

  const layoutContainerOptions = {
    layoutContainer: { ref: layoutContainerRef, 'data-survey-format': survey?.format, 'data-page-id': currentPageId },
    partialContainer: { ref: partialContainerRef },
    topContainer: { ref: topContainerRef },
    mainContainer: { className: layoutMainContainer, ref: mainContainerRef },
    logoContainer: { ref: logoContainerRef },
    toolbarContainer: { ref: toolbarContainerRef },
  };

  const settings: RespondentSurveyCapabilityProviderProps['settings'] = {
    survey: {
      numberingEnabled: !!isQuestionNumberingEnabled,
      asteriskEnabled: !!showRequiredQuestionAsterisks,
      okLabel: decodeHtmlEntities(labels.ok) ?? t(COPY.OK_LABEL),
      isClassic: isClassicMode,
    },
    uploadFile: async (file: File) =>
      new Promise<{ id: string; url: string; error?: string }>(resolve => {
        setTimeout(() => {
          console.warn('Hook up to S3 not yet implemented');
          resolve({ id: 'Q001', url: '', error: 'Connection to S3 not yet implemented' });
        }, 250);
      }),
    theme: surveyTheme,
    locale: reqLocale,
  };

  /**
   * @todo revisit signature of `onLeaveQuestion`; externalQuestion argument is
   * not used. (should `questions` be swapped with `externalQuestion` in this case?)
   */
  const upstreamActions: RespondentSurveyCapabilityProviderProps['upstreamActions'] = {
    onLeaveQuestion: (externalQuestion, answer) => {
      if (questions && answer) {
        dispatch(updateAnswer(answer));
      }
    },
  };

  const isLastSurveyPage = (): boolean => {
    return pagePath.length <= 1 || pagePath.length === currentPagePosition;
  };

  if (hasError) {
    return <FiveHundredErrorPage />;
  }

  return (
    <>
      {isOQAAT && <ActiveViewScrollManager />}
      {!getClientEnvironmentDetails().isMobile && (
        <BypassBlock>
          <a href={`#${skipContentId}`}>
            <T desc={COPY.SKIP_LINK_CONTENT} />
          </a>
        </BypassBlock>
      )}
      {hasVersionChanged && <ModifiedSurveyDialog onConfirmClick={handlePageReload} />}
      <Layout
        logo={!!isLogoEnabled && <Logo {...logoProps} />}
        tools={
          currentPagePosition === 1 ? (
            <div>
              <LanguageSelect selectedLangCode={reqLocale} languages={languages} />
            </div>
          ) : (
            false
          )
        }
        options={layoutContainerOptions}
      >
        <div id={`survey-title-container-${surveyId}`} className={surveyTitleContainer}>
          {isSurveyTitleEnabled && (
            <SurveyTitle
              id={surveyTitleId}
              align={surveyTitleAlignment}
              element={nextHeadingElement(0)}
              text={surveyTitleHTML ?? ''}
            />
          )}
          {/* <SurveyIntro text={surveyIntro} /> */}
          {/* <SurveyButton translate="no" size="md">OK</SurveyButton> */}
        </div>
        <main id={mainContentId} ref={mainRef} className={mainContainer} tabIndex={-1}>
          {isCurrentViewCustomThankYou && (
            <div id={`custom-thank-you-container-${surveyId}`} className={customThankyouContainer}>
              {collector?.thankYouPage?.message}
            </div>
          )}
          {isCurrentViewSurveyTaking && (
            <>
              <div id={`page-header-container-${surveyId}`}>
                {displayPageTitle && (
                  <PageTitle
                    id={pageTitleId}
                    element={nextHeadingElement(1)}
                    text={pageTitle ?? ''}
                    numbered={showPageNumbers}
                    number={currentPage}
                  />
                )}
                {!!pageDescription && (
                  <SurveyFormatView name="pageDescription" noShield>
                    {(isActive, setNextActive) => (
                      <>
                        <PageDescription text={pageDescription} />
                        {isOQAAT && (
                          <SurveyButton
                            size="md"
                            translate="no"
                            onClick={setNextActive}
                            className={classNames(pageDescriptionOkButton, { [sronlyFocus]: !isActive })}
                          >
                            {labels.ok || t(COPY.OK_LABEL)}
                          </SurveyButton>
                        )}
                      </>
                    )}
                  </SurveyFormatView>
                )}

                {isProgressBarEnabled && isClassicMode && progressBarPosition === 'TOP' && (
                  <div className={classicProgressBarTop}>
                    <ClassicProgressBar
                      value={currentPage}
                      total={totalPages ?? 1}
                      showPageCount={showPageCount ?? true}
                      showPercentage={showPercent ?? true}
                      isRTL={isRTL}
                    />
                  </div>
                )}
              </div>

              <RespondentSurveyCapabilityProvider settings={settings} upstreamActions={upstreamActions}>
                <form id={`form-${surveyId}`} className={questionContainer}>
                  {/**
                   * NOTE: active (boolean) will be controlled by Progression Engine
                   * NOTE: Are all the nullified fields (questionBank, validation) needed?
                   */}
                  <QuestionList
                    questionNumberType={survey.design?.questionNumbering?.type}
                    questions={questions?.items as ExternalQuestion[]}
                    answers={answers}
                    isOQAAT={isOQAAT}
                  />
                  {showWeblinkConfirmationToggle && isLastSurveyPage() && (
                    <div id="weblink-confirmation-checkbox">
                      <WeblinkConfirmationCheckbox
                        onBlur={setWeblinkConfirmationEmail}
                        onToggle={setWeblinkConfirmationEmailChecked}
                      />
                    </div>
                  )}
                  {/**
                   *  When survey is in OQAAT mode, progress bar is below the survey footer and is sticky
                   */}
                  {isProgressBarEnabled && isOQAAT && (
                    <PersistentProgressBar
                      value={questionsWithAnswersCount}
                      total={answerableQuestionCount}
                      ref={persistentProgressRef}
                      isRTL={isRTL}
                    />
                  )}
                  {isProgressBarEnabled && isClassicMode && progressBarPosition === 'BOTTOM' && (
                    <ClassicProgressBar
                      value={currentPage}
                      total={totalPages ?? 1}
                      showPageCount={showPageCount ?? true}
                      showPercentage={showPercent ?? true}
                      isRTL={isRTL}
                    />
                  )}
                </form>
              </RespondentSurveyCapabilityProvider>
            </>
          )}
          <footer id={`page-footer-container-${surveyId}`} className={pageFooterContainer}>
            {pagePath.length > 1 && (
              <SurveyFormatView name="pageNavigation" noShield autoPadding={false}>
                <PageNavigation
                  ref={pageNavigationRef}
                  className={pageNavigationContainer}
                  labels={labels}
                  currentPage={currentPagePosition}
                  totalPages={pagePath?.length || 1}
                  onNext={() => {
                    void _submitPageV3(
                      pagePath[currentPagePosition - 1].toString(),
                      pagePath[currentPagePosition].toString()
                    );
                  }}
                  onPrevious={() => {
                    void _submitPageV3(
                      pagePath[currentPagePosition - 1].toString(),
                      pagePath[currentPagePosition - 2].toString(),
                      true
                    );
                  }}
                  onDone={() => {
                    void _submitSurveyV3();
                  }}
                  disableSubmit={disableSubmit}
                  showPrevious={collector?.editResponseType !== 'NEVER'}
                />
              </SurveyFormatView>
            )}
            {pagePath.length <= 1 && (
              <SurveyFormatView name="pageNavigation" noShield autoPadding={false}>
                <PageNavigation
                  ref={pageNavigationRef}
                  className={pageNavigationContainer}
                  labels={labels}
                  currentPage={currentPagePosition}
                  totalPages={pagePath?.length || 1}
                  onDone={() => {
                    void _submitSurvey();
                  }}
                  disableSubmit={disableSubmit}
                />
              </SurveyFormatView>
            )}
          </footer>
        </main>
        <footer id={`survey-footer-container-${surveyId}`} className={surveyFooterContainer} ref={surveyFooterRef}>
          {isCurrentViewSurveyTaking && isFooterEnabled && (
            <SurveyFooter
              element="div"
              showRequestId={showRequestId}
              pageRequestId={pageRequestId}
              isGrayScale={!!survey?.design?.footer?.grayscale}
              showPrivacyCookiePolicy={currentPage === 1}
              // Below are placeholders, fill in when we get info from query
              showReportProblem={false}
              reportProblemURL=""
              isGoogleSubdomain={false}
              googlePrivacyPolicyURL=""
              surveyId={surveyId}
              collectorId={collector?.id}
              collectorType={collector?.type}
            />
          )}
        </footer>
      </Layout>
    </>
  );
};

export default Survey;
